import styled from 'styled-components';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
  .apphub-drawer-header {
    padding: 24px 8px 0 20px;
    border: none;
  }

  .apphub-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
    user-select: none;
  }

  .apphub-drawer-close {
    margin: 0;
    font-size: 22px;
  }

  .apphub-drawer-title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Open Sans';
  }

  .apphub-drawer-body {
    padding: 0;
  }
`;

export const StyledSection = styled.div`
  padding: 24px 20px;

  .title {
    color: #9a9a9a;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    font-family: Open Sans;
    user-select: none;
  }

  .link {
    display: block;
    margin-top: 12px;
    color: #0046ff;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: Open Sans;
    user-select: none;

    .anticon {
      margin-left: 10px;
      font-size: 18px;
    }

    &.failed,
    &.loading {
      pointer-events: none;
      color: #9a9a9a;
      display: flex;
      align-items: center;
    }

    &.failed {
      pointer-events: auto;

      .anticon {
        color: rgb(202, 34, 22);
      }
    }
  }

  & + & {
    border-top: solid 1px #d6d6d6;
  }
`;

export const DepSubTitle = styled.span`
    font-size: 10px;
    color: tomato;
    font-style: italic;
`;

