import * as React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { DepSubTitle, StyledSection } from './styled';
import { FEATURE_SETTINGS_MENUS } from '@constants/subMenu';

export interface SettingPanelSectionItemProps {
  name: string;
  status?: string | boolean;
  url?: string;
  onClick?: () => void | Promise<void>;
  onLinkClick?: () => void;
}

export interface SettingPanelSectionProps {
  name?: string;
  items: Array<Omit<SettingPanelSectionItemProps, 'onLinkClick'>>;
  onLinkClick?: () => void;
}

const SettingPanelSectionItem = (props: SettingPanelSectionItemProps) => {
  const [status, setStatus] = React.useState<string | boolean>();
  const finalStatus = props.status ?? status;

  return (
    <a
      key={props.name}
      href={props.url}
      style={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 1.5
      }}
      className={classnames(
        'link',
        finalStatus === false && 'loading',
        typeof finalStatus === 'string' && 'failed',
      )}
      onClick={async e => {
        e.preventDefault();

        if (props.onClick) {
          try {
            setStatus(false);
            await props.onClick();
            setStatus(true);
          } catch (error) {
            setStatus((error as Error).message || 'unknown error');

            return;
          }
        } else window.open(props.url, '_blank');

        props.onLinkClick?.();
      }}
    >
      {typeof finalStatus === 'string' ? (
        <Tooltip title={finalStatus}>
          {props.name}
          <WarningOutlined />
        </Tooltip>
      ) : (
        props.name
      )}

      {finalStatus === false && <LoadingOutlined />}

      {props.name === FEATURE_SETTINGS_MENUS.CONVIVA_EXECUTIVE_REPORT && (
        <DepSubTitle>Deprecating Soon</DepSubTitle>
      )}
    </a>
  );
};

const SettingPanelSection = (props: SettingPanelSectionProps) => {
  return (
    <StyledSection>
      {props.name && <div className="title">{props.name}</div>}
      {props.items.map(it => (
        <SettingPanelSectionItem key={it.name} {...it} onLinkClick={props.onLinkClick} />
      ))}
    </StyledSection>
  );
};

export default React.memo(SettingPanelSection);
