import React from 'react';
import { useNav } from '@subApps/navApp/hooks/useData';
import { useProduct } from '@subApps/navApp/hooks/useGlobal';
import PRODUCTS from '@constants/products';
import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { FEATURE_SETTINGS_MENUS } from '@constants/subMenu';

const DepNotice = () => {
  const { data: product } = useProduct();
  const { data: nav = { ei_setting: [] } } = useNav();
  const hasExecEmail = nav.ei_setting.some(m => m.name === FEATURE_SETTINGS_MENUS.CONVIVA_EXECUTIVE_REPORT) && product !== PRODUCTS.APP;

  return hasExecEmail ? (<div style={{ margin: '0px 10px' }}>
    <Alert
      message="Executive Email 2.0 will be deprecated on 8th May 2025. We advise customers to migrate these emails to Custom Dashboard's Schedule Management, which now provides Date Compare functionality."
      type="error"
      showIcon
      closable
      icon={<InfoCircleFilled />}
    />
  </div>) : null;
}

export default DepNotice;
